import type { PictureDto } from '~/api/client/api';
import type { ImageLoader } from 'next/image';
import Image from 'next/image';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const placeholderImages = {
  boat: `${publicRuntimeConfig.ASSETS_PREFIX}/images/placeholder-img.svg`,
  region: `${publicRuntimeConfig.ASSETS_PREFIX}/images/placeholder-img.svg`,
};

if (typeof btoa === 'undefined') {
  global.btoa = function (str) {
    return Buffer.from(str, 'binary').toString('base64');
  };
}

/**
 * Returns stringified request config for AWS Serverless Image Handler.
 */
const getImageRequest = (src: string, desiredWith: number, desiredHeight?: number) => {
  return JSON.stringify({
    bucket: publicRuntimeConfig.IMAGES_BUCKET,
    key: src,
    edits: {
      resize: {
        width: desiredWith,
        height: desiredHeight,
      },
    },
  });
};

const getHashedImageSrc = (imageObj: PictureDto, desiredWidth?: number, desiredHeight?: number) => {
  const { Hostname, Guid, Extension } = imageObj;

  if (Hostname === null || Guid === null || Extension === null) {
    return '';
  }

  const src = Guid + '.' + Extension;

  const imageRequest = getImageRequest(src, desiredWidth, desiredHeight);

  return `${Hostname}${btoa(imageRequest)}`;
};

/**
 * A custom function used to resolve URLs for next/image.
 *
 * @param cropRatio Image ratio to crop to. If `cropRatio` is defined, the image will be cropped based on image width and provided ratio. If `cropRatio` is undefined, the image will not be cropped.
 * @returns Formated URL string from AWS Serverless Image Handler
 */
const myLoader = (hostName: string, cropRatio?: number): ImageLoader => {
  return ({ src, width }): string => {
    const imageRequest = getImageRequest(src, width, cropRatio ? Math.round(width * cropRatio) : undefined);

    return `${hostName}${btoa(imageRequest)}`;
  };
};

/**
 * Returns `next/image` component.
 *
 * @param img PictureDto object to display.
 * @param width The width of the image, in pixels. Must be an integer without a unit.
 * @param height The height of the image, in pixels. Provide only if you need to crop the image to fit in required bounbdaries. Must be an integer without a unit.
 * @param sizes A string mapping media queries to device sizes. Defaults to `100vw`.
 */
const getNextImageElement = (img: PictureDto, width = 900, height?: number, sizes = '100vw'): JSX.Element => {
  if (!height) {
    height = Math.round((img.Height / img.Width) * width);
  }

  return (
    <Image
      width={width}
      height={height}
      loader={myLoader(img.Hostname, height / width)}
      src={img.Guid + '.' + img.Extension}
      onError={(e: any) => {
        e.target.src = placeholderImages.region;
        e.target.srcset = placeholderImages.region;
      }}
      layout="responsive"
      sizes={sizes}
    />
  );
};

export { placeholderImages, getNextImageElement, getHashedImageSrc };
